import React, { Component } from 'react';
import Toolbar from '../../Components/navigation/toolbar/toolbar';
import MenuTraiteur from '../../Components/menuSections/menuTraiteur/menuTraiteur';

class Traiteur extends Component {
    render() {
        return (
            <div>
                <MenuTraiteur>
                <Toolbar />
                </MenuTraiteur>
            </div>
        );
    }
}

export default Traiteur;
import React from 'react';
import './footer.css';
import { Container, Col, Row } from 'reactstrap';
import heart from '../../../Assests/icons/footer/heart.png';
import telephone from '../../../Assests/icons/contact.png';
import email from '../../../Assests/icons/email.png';
import map from '../../../Assests/icons/map.png';
import fb from '../../../Assests/icons/footer/fb.png'

const footer = () => {
    return (
        <footer id="footer" className="footer">
            <Container>
            <Row>
                <Col>
                <div className='elements-footer'>
                <table className='contact'>
                    <tbody>
                    <tr>
                        <td><a href="https://www.facebook.com/Le-bar-%C3%A0-couscous-de-P%C3%A9renchies-102690795858883" target="_blanck"><img src={fb} alt="facebook" />&nbsp;&nbsp;Le bar à Couscous sur Facebook</a></td>
                        <td><img src={telephone} alt="telephone" />&nbsp;&nbsp;03 20 47 14 82</td>
                        <td><img src={map} alt="map" />&nbsp;&nbsp;5 rue Edouard Agache<br /> 59840 Perenchies</td>
                        <td><img src={email} alt="email" />&nbsp;&nbsp;contact@lebaracouscous.fr</td> 
                        </tr>
                        </tbody>
                </table>
           
                <table className='time'>
                <tbody>
                <tr>
                        <td className='jour'>Lundi</td>
                        <td className='heures'>-</td>
                        <td className='heures'>-</td>
                    </tr>
                    <tr>
                        <td className='jour'>Mardi au Jeudi</td>
                        <td className='heures'>-</td>
                        <td className='heures'>17h00 - 21h15</td>
                    </tr>
                    <tr>
                        <td className='jour'>Vendredi</td>
                        <td className='heures'>-</td>
                        <td className='heures'>17h00 - 21h15</td>
                    </tr>
                    <tr>
                        <td className='jour'>Samedi</td>
                        <td className='heures'>-</td>
                        <td className='heures'>17h00 - 21h15</td>
                    </tr>
                    <tr>
                        <td className='jour'>Dimanche</td>
                        <td className='heures'>11h45 - 14h00</td>
                        <td className='heures'>-</td>
                    </tr>
                    </tbody>
                </table>
                <div className="container-indic-time"><span className="indic-time">Possibilité de commander 24h à l'avance pour les matinées fermées, à partir de 12 couscous.<br />Nous contacter pour plus d'informations.</span></div>
        </div>
                </Col>
            </Row>
                <Row>
                    <Col>
                        <p className="footer-text">
                            COPYRIGHT 2022 reserved by Le Bar à Couscous<br /><br/>
                            Made with <img src={heart} alt="Heart" style={{ height: "22px", padding: "0 3px", float: "none" }} /> by <a style={{ display: "inline", float: "none" }} href="https://www.louisallard.fr">Louis Allard</a></p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}
export default footer;
import React, { Component } from 'react';
import Home from '../Home/Home';
import Menu from '../Menu/Menu';
import Traiteur from '../Traiteur/Traiteur';
import { Switch, Route } from 'react-router-dom';

class allClass extends Component {
    render() {
        return (
            <div>
                <Switch>
                <Route path="/traiteur" component={() => <Traiteur /> } />
                <Route path="/menu" component={() => <Menu />} />
                <Route path="/" component={() => <Home />} />
                </Switch>
            </div>
        );
    }
}

export default allClass;


import React, { Component } from 'react';
import Toolbar from '../../Components/navigation/toolbar/toolbar';
import Menuheader from '../../Components/menuSections/menuHeader/menuHeader';


class Menu extends Component {
    render() {
        return (
            <div>
                <div>
                    <Menuheader>
                        <Toolbar />
                    </Menuheader>
                </div>;
            </div>
        );
    }
}

export default Menu;
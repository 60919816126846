import React, { Component } from 'react';
import "./toolbar.css";
import { toolbarmenu } from '../../../lng/fr';
import { Container, Row, Col } from 'reactstrap';
import menu from '../../../Assests/icons/menu.png';
import home from '../../../Assests/icons/home.png';
import chief from '../../../Assests/icons/chief.png';
import { Link } from 'react-router-dom';
import logo from '../../../Assests/logo-big.png';
class toolbar extends Component {
    render() {
        return (
            <header>
                <nav>
                    <div className="t-ext">
                        <Container>
                            <Row> 
                                <img className='logo-menu' src={logo} alt="Logo"/>   
                                <Col className='entry'>
                                    <div className="toolbar">
                                        <img src={home} alt="Home Menu Icon" />
                                        <Link to="/"><p>{ toolbarmenu.home }</p></Link>
                                    </div>
                                </Col>
                                <Col className='entry'>
                                    <div className="toolbar">
                                        <img src={menu} alt="Menu Opening Icon" />
                                        <Link to="/menu"><p>{ toolbarmenu.menu }</p></Link>
                                    </div>
                                </Col>
                                <Col className='entry'>
                                    <div className="toolbar">
                                        <img src={chief} alt="Menu Opening Icon" />
                                        <Link to="/traiteur"><p>{ toolbarmenu.traiteur }</p></Link>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </nav>
            </header>
        );
    }

}

export default toolbar;
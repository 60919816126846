import React, { Component } from "react";
import "./section.css";
import Brand from "../../miscelleous/brand/brand";
import Specs from "../section-specs/specs";
import Stacked from "../stacked-photos/stacked";
import Footer from "../../navigation/footer/footer";
import telephone from "../../../Assests/icons/contact.png";
import email from "../../../Assests/icons/email.png";
import map from "../../../Assests/icons/map.png";

let back = <Brand />;
class section1 extends Component {
  state = {
    cls1: "circle",
    cls2: "circle",
    cls3: "circle",
    brand: false,
  };
  componentDidMount() {
    setTimeout(() => this.clickBtn(1), 500);
  }
  clickBtn(valv) {
    if (valv === 1) {
      back = <Brand show />;
      this.setState({ cls2: "circle" });
      this.setState({ cls3: "circle" });
      this.setState({ cls1: "full" });
    }
  }
  render() {
    return (
      <div style={{ overflowY: "hidden" }}>
        <section className="Header">
          {this.props.children}
         {/*  <span className="plats">Plats a emporter</span> <br /> */}
        <section className="sec-stacked">
          <Stacked />
        </section>
        <section className="specs2">
          <Specs />
        </section>
          {back}
          <div className="controls"></div>
        </section>
        <section className="specs">
          <div className="elements">
            <table className="contact">
              <tbody>
              <tr>
                <td>
                  <img src={telephone} alt="telephone" />
                  &nbsp;&nbsp;03 20 47 14 82
                </td>
                <td>
                  <img src={map} alt="map" />
                  &nbsp;&nbsp;5 rue Edouard Agache, 59840 Perenchies
                </td>

                <td>
                  <img src={email} alt="email" />
                  &nbsp;&nbsp;contact@lebaracouscous.fr{" "}
                </td>
              </tr>
              </tbody>
            </table>

            <table className='time'>
                <tbody>
                <tr>
                        <td className='jour'>Lundi</td>
                        <td className='heures'>-</td>
                        <td className='heures'>-</td>
                    </tr>
                    <tr>
                        <td className='jour'>Mardi au Jeudi</td>
                        <td className='heures'>-</td>
                        <td className='heures'>17h00 - 21h15</td>
                    </tr>
                    <tr>
                        <td className='jour'>Vendredi</td>
                        <td className='heures'>-</td>
                        <td className='heures'>17h00 - 21h15</td>
                    </tr>
                    <tr>
                        <td className='jour'>Samedi</td>
                        <td className='heures'>-</td>
                        <td className='heures'>17h00 - 21h15</td>
                    </tr>
                    <tr>
                        <td className='jour'>Dimanche</td>
                        <td className='heures'>11h45 - 14h00</td>
                        <td className='heures'>-</td>
                    </tr>
                    </tbody>
                </table>
                <div className="container-indic-time"><span className="indic-time">Possibilité de commander 24h à l'avance pour les matinées fermées, à partir de 12 couscous.<br />Nous contacter pour plus d'informations.</span></div>
            
          </div>
        </section>

        <section className="sec-map">
          {
            <div style={{ width: " 100%" }}>
              <iframe
                src="https://maps.google.com/maps?q=5%20rue%20edouard%20agache,%20perenchie&t=&z=13&ie=UTF8&iwloc=&output=embed"
                style={{ height: "80vh", width: "100vw" }}
                title="carte"
              ></iframe>
            </div>
          }
        </section>
        <Footer />
      </div>
    );
  }
}
export default section1;

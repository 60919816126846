import React from 'react';
import img1 from '../../../Assests/images//facade.jpg';
import img2 from '../../../Assests/images/stacked/couscous-classique-big.jpg';
import img3 from '../../../Assests/images/stacked/tajine-big.jpg';
import img4 from '../../../Assests/images/stacked/cuistaut.jpg';
import './brand.css';

const brand=(props)=>{
    return(
        <div className='logo'>
<div className="Slideshow">
    <div className="Slideshow-Content">
        <img className="un" src={img1} alt="img1" />
        <img className="deux" src={img2}  alt="img2" />
        <img  className="trois" src={img3}  alt="img3"  />
        <img  className="quatre" src={img4}  alt="img4"  />
        <img className="cinq" src={img1} alt="img1" />

    </div>
</div>
   
        </div>
    );
}

export default brand;
import React, { Component } from 'react';
import Toolbar from '../../Components/navigation/toolbar/toolbar';
import Header from '../../Components/sections/section-header/section';
import Alert from '../Alerts/Alerts';
class Home extends Component {
    render() {
        return (
            <div>
                <Header>
                    <Toolbar />
                    <Alert />
                 </Header>
            </div>
        );
    }

}

export default Home;
import React from 'react';
import  './menuTraiteur.css';
import TraiteurAll from '../traiteurAll/traiteurAll';
import Footer from '../../navigation/footer/footer';
const menuTraiteur=(props)=>{
    return(
        <div>
         <section className="menuTraiteur">
         {props.children}
        </section> 
        <section className="menu-options">
            <TraiteurAll />
        </section>
          <Footer/>  
        </div>
    );
}

export default menuTraiteur;
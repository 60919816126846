import React, { Component } from "react";
import { carte } from "../../../lng/fr";
import entree from "../../../Assests/images/menu/entree.jpg";
import couscousclassique from "../../../Assests/images/menu/couscous-classique.jpg";
import supplements from "../../../Assests/images/menu/supplements.jpg";
import boissons from "../../../Assests/images/menu/boissons.jpg";
import desserts from "../../../Assests/images/menu/desserts.jpg";
import tajines from "../../../Assests/images/menu/tajines.jpg";
import plats from "../../../Assests/icons/icon_specs/plats.png"
import leaf from "../../../Assests/icons/icon_specs/blueleaf.png"

import "./menuAll.css";
class menuAll extends Component {
  state = {
    options: [],
    load: "Select From Above",
    showCustomize: false,
    sendData: {},
  };
  openCustomizeHandler = (obj) => {
    this.setState({ showCustomize: true });
  };
  closeCustomizationHandler = () => {
    this.setState({ showCustomize: false });
  };
  loadStateHandler = (name) => {
    this.setState({ load: name, sendData: this.props.data[name] });
  };
  render() {
    return (
      <div className="menu">
        <span className="products"><img className="ico-menu" src={plats} alt="couscous box" /> Produits frais & locaux <img className="ico-menu" src={leaf} alt="couscous box" /> Plats faits maison</span>
        <div className="grid-container">
          <div className="grid-item">
            <span className="title">{carte.entree}</span>
            <div className="carte">
              <ul className="mainlist">
                <li>Carottes à la Chermoula <span className="media-change">(marinées à l'orientale)</span> <span className="prix">4,90 €</span></li>
{/*                 <li>Soupe Harira ou Chorba <span className="media-change">(selon disponibilité)</span> <span className="prix">4,90 €</span></li>
 */}            <li>Pastilla aux fruits de mer* <span className="prix">10,90 €</span></li>
                <li>Pastilla au poulet* <span className="prix">9,90 €</span></li>
                <li>Bricks kefta et fromage* (X4) <span className="prix">9,90 €</span></li>
              </ul>
             <i>*sur commande 24h</i>
            </div>
          </div>
          <div className="grid-item-img">
            <img className="img-menu" src={entree} alt="entree" />
          </div>
   {/*        <div className="grid-item-img">
            <img className="img-menu" src={couscousbox} alt="couscous box" />
          </div> 
          <div className="grid-item">
            <span className="title">{carte.plat_box}</span>
            <div className="carte">
             <span className="b-color">Une couscous Box achetée = une boisson offerte !</span><br />
           <i><b>Uniquement le vendredi midi</b></i> 
              <p>
              <i><b>Base:</b> semoule, bouillon, carottes, navets, courgettes, pois chiches, raisins secs</i>
              </p>
              <ul className="mainlist">
                <li>Végétarien <span className="prix">9,90 €</span></li>
                <li>Merguez (x2) <span className="prix">12,90 €</span></li>
                <li>
                  Kefta <span className="media-change">(boulettes de viande hachée marinées)</span><span className="prix">12,90 €</span>
                </li>
                <li>Brochettes poulet (x2) <span className="prix">12,90 €</span></li>
                <li>Brochettes agneau (x2) <span className="prix">13,90 €</span></li>
              </ul>
            </div>
        </div>*/} 
                  <div className="grid-item-img">
            <img className="img-menu-img" src={couscousclassique} alt="couscous classique" />
          </div>
           <div className="grid-item">
            <span className="title">{carte.plat_classique}</span>
            <div className="carte">
              <i><b>À déguster chez soi</b></i>
              <p><i><b>Base:</b> semoule, bouillon, carottes, navets, courgettes, pois chiches, raisins secs, harissa</i></p>
              <ul className="mainlist">
                <li>Végétarien <span className="prix">13,90 €</span></li>
                <li>Merguez (x2) <span className="prix">16,90 €</span></li>
                <li>
                  Kefta <span className="media-change">(boulettes de viande hachée marinées)</span> <span className="prix">16,90 €</span>
                </li>
                <li>Poulet fermier local (pièce) <span className="prix">16,90 €</span></li>
                <li>Brochettes de poulet (x2) <span className="prix">16,90 €</span></li>
                <li>Agneau (pièce) <span className="prix">17,90 €</span></li>
                <li>Brochettes d'agneau (x2) <span className="prix">17,90 €</span></li>
                <li>
                  Royal (merguez, kefta, brochette d'agneau, poulet pièce*) <span className="prix">23,90 €</span>
                  <br />
                  <i>*remplacable par une brochette</i>
                </li>
                <li>
                  Méchoui* <span className="prix">24,90 €</span>
                  <br />
                  <i>* Méchoui sur commande 48h en avance. 4 personnes minimum</i>
                </li>
              </ul>
            </div>
          </div> 

      
          <div className="grid-item">
            <span className="title">{carte.plat_tajines}</span>
            <div className="carte">
              <ul className="mainlist">
                <li>
                  Agneau <span className="prix">17,90 €</span>
                  <ul className="sublist">
                    <li>Pruneaux et abricots aux amandes</li>
                    <li>Aux légumes* et aux olives</li>
                  </ul>
                </li>
                <li>
                  Poulet fermier local <span className="prix">16,90 €</span>
                  <ul className="sublist">
                    <li>Pruneaux et abricots aux amandes</li>
                    <li>Aux légumes* et aux olives</li>
                    <li>Aux raisins et aux oignons caramélisés</li>
                  </ul>
                </li>
                <li>
                  Kefta <span className="prix">16,90 €</span>
                  <br />
                  <i>Boulettes de viande hachée marinées, oeuf plein air, olives</i>
                </li>
              </ul>
              <i>*pommes de terre, petits pois et carottes</i>
            </div>
          </div>
          <div className="grid-item-img">
            <img className="img-menu" src={tajines} alt="plat" />
          </div>
          <div className="grid-item-img">
            <img className="img-menu" src={supplements} alt="supplements" />
          </div>
          <div className="grid-item">
            <span className="title">{carte.plat_supplements}</span>
            <div className="carte">
              <ul className="mainlist">
                <li>Semoule (portion) <span className="prix">5,00 €</span></li>
                <li>Légumes (portion) <span className="prix">5,00 €</span></li>
                <li>Agneau <span className="prix">6,00 €</span></li>
                <li>Brochettes d'agneau (x2) <span className="prix">6,00 €</span></li>
                <li>Poulet fermier local <span className="prix">5,00 €</span></li>
                <li>Brochettes de poulet (x2)<span className="prix">5,00 €</span></li>
                <li>Kefta <span className="prix">5,00 €</span></li>
                <li>Merguez (x2) <span className="prix">5,00 €</span></li>
              </ul>
            </div>
          </div>
     
          <div className="grid-item">
            <span className="title">{carte.plat_boissons}</span>
            <div className="carte">
              <ul className="mainlistDrink">
                <li>
                  Eau plate (50cl), Perrier, Coca Cola, Coca Cola sans sucre,<br />
                  Fanta, Orangina, Oasis, Sweppes, Sprite, jus de fruits <span className="prix">1,50 €</span>
                </li>
                <li>Vins rosés orientaux (selon arrivage) <span className="prix">13,90 €</span></li>
              </ul><br /><br />
              Thé maison offert sur place
            </div>
          </div>
          <div className="grid-item-img">
            <img className="img-menu" src={boissons} alt="boissons" />
          </div>
          <div className="grid-item-img">
            <img className="img-menu" src={desserts} alt="boissons" />
          </div>
          <div className="grid-item">
            <span className="title">{carte.plat_desserts}</span>
            <div className="carte">
              <ul className="mainlistDrink">
                <li>
                  Boîte de quatre gâteaux orientaux <span className="prix">6,00 €</span>
                </li>
              </ul>
              <i>Selon disponibilité</i>
            </div>
          </div>
    
        </div>
      </div>
    );
  }
}

export default menuAll;

import React, { Component } from "react";
import DOMPurify from 'dompurify';
import { traiteur } from "../../../lng/fr";

import "./traiteurAll.css";

class traiteurAll extends Component {
  render() {
    return (
<div className="traiteurAll">
    <div className="head-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(traiteur.headtext) }}></div>
    <div className="map-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(traiteur.maptext) }}></div>
  {/*   <div className="chief-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(traiteur.chieftext) }}></div> */}
    <div className="devises"><div className="container">
   <div className="col-title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(traiteur.coltitle) }}></div>
  <div className="row">
    <div className="col-sm">
    <div className="col1-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(traiteur.col1) }}></div>
    </div>
    <div className="col-sm">
    <div className="col2-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(traiteur.col2) }}></div>
    </div>
    <div className="col-sm">
    <div className="col3-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(traiteur.col3) }}></div>
    </div>
  </div>
</div></div>
    <div className="traiteur-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(traiteur.traiteurtext) }}></div>
</div>
    );
  }
}

export default traiteurAll;

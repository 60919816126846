import React from 'react';
import  './menuHeader.css';
import MenuAll from '../menuAll/menuAll';
import Footer from '../../navigation/footer/footer';
const menuHeader=(props)=>{
    return(
        <div>
         <section className="menuHeader">
         {props.children}
        <span className="bigtitle">Menu</span>
        </section> 
        <section className="menu-options">
            <MenuAll adding={props.adding} data={props.data}/>
        </section>
          <Footer/>  
        </div>
    );
}

export default menuHeader;
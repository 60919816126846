import React, { Component } from "react";
import flag from "../../Assests/drapeau.png";
import "./Alerts.css";

class Alerts extends Component {
  render() {
    return (
      <div className="Alerts">
        <p> {/* <b>Horaires d'été</b>
        
                        
               <table className='time'>
                <tr>
                        <td className='jour'>Lundi</td>
                        <td className='heures'>-</td>
                        <td className='heures'>-</td>
                    </tr>
                    <tr>
                        <td className='jour'>Mardi au vendredi</td>
                        <td className='heures'>-</td>
                        <td className='heures'>17h00 - 21h00</td>
                    </tr>
                    <tr>
                        <td className='jour'>Samedi</td>
                        <td className='heures'>-</td>
                        <td className='heures'>17h00 - 21h00</td>
                    </tr>
                    <tr>
                        <td className='jour'>Dimanche</td>
                        <td className='heures'>12h00 - 14h00</td>
                        <td className='heures'>-</td>
                    </tr>
                </table>*/}
                
          <b>Venez assister ce vendredi 06 septembre jusqu'au dimanche 08 inclus à l'exposition sur la bataille de Bir Hakeim.</b>
          <img src={flag}  className="flag" alt="drapeau francais" title="france" />
        </p>
      </div>
    );
  }
}

export default Alerts;